import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from './Carousel';
import ipad from '../assets/iapd.jpeg'
import asd from '../assets/Frame 34.png'
import { useIntl } from 'react-intl';
import comment from '../assets/scribble.svg'
import LoadingSpinner from '../modifier/LoadingSpinner';

const ProductCard = ({ name, slogan, imageUrl, date, productInfo }) => {
  const languageCode = window.location.pathname.split('/')[1] || 'en';
  const intl = useIntl();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [isCommit, setIsCommit] = useState(false);
  const imageRef = useRef(null);

    // 新增状态来追踪是否显示输入框和提交按钮
    const [showInput, setShowInput] = useState(false);
  const [textAreaContent, setTextAreaContent] = useState(''); // 新增状态来存储textarea的内容


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageLoaded(true); // 触发图片加载
            observer.unobserve(entry.target); // 加载后取消观察
          }
        });
      },
      {
        rootMargin: '100px', // 提前开始加载的距离
        threshold: 0.01, // 目标可见性达到1%时触发
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // 点击事件处理函数
  // 在ProductCard组件中
  const handleCardClick = () => {
    const productDetailUrl = `/${languageCode}/product-detail/${productInfo}`;
    window.open(productDetailUrl, '_blank'); // 确保使用 '_blank' 打开新标签页
  };

  // 处理点击评论图标的事件
  const handleCommentClick = (event) => {
    event.stopPropagation(); // 阻止事件冒泡
    setShowInput(!showInput); // 切换输入框和提交按钮的显示状态
  };

  // 阻止textarea和button的事件冒泡
  const handleInputInteraction = (event) => {
    event.stopPropagation();
    // setShowInput(false)
  };

  const handleTextAreaChange = (event) => {
    setTextAreaContent(event.target.value);
  };

  // 新增上传函数
  const uploadContent = (event) => {
    event.stopPropagation(); // 防止触发父级事件
      // 判断内容是否为空
  if (!textAreaContent.trim()) {
   setShowInput(false)
    return; // 直接返回，不执行上传
  }
  setIsCommit(true)
    const preSignedUrl = `https://${process.env.REACT_APP_BUCKET_NAME}.${process.env.REACT_APP_OSS_SERVER}${process.env.REACT_APP_COMMENT_PATH}/${productInfo}/${textAreaContent}`;

    fetch(preSignedUrl, {
      method: 'PUT',
      // body: textAreaContent // 上传textarea的内容
    })
    .then(response => {
      if (response.ok) {
        alert(intl.formatMessage({ id: 'submit' }));
        setShowInput(false)
        setTextAreaContent('')
      } else {
        alert(intl.formatMessage({ id: 'emailUploadError' }));
      }
    })
    .catch((error) => {
      console.error('上传出错:', error);
      alert('上传过程中出现错误');
    })
    .finally(() => {
      setIsCommit(false); // 无论上传成功还是失败，都取消提交状态
    });
  };

  return (
    <div
      className="group bg-white rounded-lg overflow-hidden shadow hover:shadow-lg hover:scale-105 transition-transform duration-300 cursor-pointer relative"
      ref={imageRef}
      onClick={handleCardClick}
    >
      <div className='h-164 overflow-hidden'>
        {!imageLoaded ? (
          <div className="animate-pulse w-full h-48 md:h-72 bg-slate-300 rounded-t-lg"></div>
        ) : (
          <>
            <div className="px-4 py-2 flex w-full  flex-row sm:flex-col">
              <div className="flex flex-col sm:h-28 justify-between py-2 sm:p-4 md:p-0">
                <div className="">{name}</div>
                <h3 className="text-sm sm:text-md md:text-lg font-semibold">{slogan}</h3>
                <div className=" text-slate-400">{date}</div>
              </div>
              <div className="flex w-1/2 sm:w-full  sm:items-center">
                <img
                  className="flex w-full h-44 md:h-72 object-contain "
                  src={imageUrl}
                  alt={name}
                  onLoad={() => setImageLoaded(true)}
                />
              </div>
              <div
               className=" absolute w-8 h-8 right-4 bottom-3 md:bottom-5 rounded-full p-2 sm:p-1 flex justify-center items-center"
               onClick={handleCommentClick} // 为评论图标添加点击事件处理函数
              >
              <img
                className="hover:p-1 hover:scale-90 transition-transform duration-300"
                src={comment}
                alt="comment"
              />
              </div>
              {showInput && (
                <div className="absolute w-full h-full top-0 left-0 rounded-lg flex justify-between">
                  <textarea
                    className="rounded-md w-full h-full p-4 text-base md:p-8 md:text-xl resize-none focus:outline-none"
                    value={textAreaContent}
                    onChange={handleTextAreaChange}
                    onClick={handleInputInteraction}
                    onFocus={handleInputInteraction}
                    placeholder={`${name} ${intl.formatMessage({ id: 'comment' })}`}
                  />
                  <button
                    className="absolute right-4 bottom-5 w-1/5 bg-gray-100 text-black font-semibold rounded-md p-1"
                    onClick={uploadContent} 
                  >
                    {isCommit ? (<>
                      <LoadingSpinner color="text-gray-500" containerClassName="flex justify-center items-center" />
                    </>):(<>
                      {intl.formatMessage({ id: 'commit' })}
                    </>)}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard
// https://www.ooakloo.top
// http://www.ooakloo.top
// http://localhost