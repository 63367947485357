// VideoComponent.js
import React, { useEffect, useRef, useState } from 'react';
const VideoComponent = ({ src, direction}) => {
    const videoRef = useRef(null);
    const [isVideoInViewport, setIsVideoInViewport] = useState(false);
    const [isAttemptingToPlay, setIsAttemptingToPlay] = useState(false); // 新增状态标志

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsVideoInViewport(entry.isIntersecting),
            { threshold: 1 } // 完全进入视口才触发
        );
    
        if (videoRef.current) {
            observer.observe(videoRef.current);
        }
    
        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);
    
    useEffect(() => {
        const playVideo = async () => {
            if (videoRef.current && !isAttemptingToPlay) { // 检查是否已经尝试播放
                setIsAttemptingToPlay(true); // 设置尝试播放标志为true
                try {
                    const isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2;
                    if (!isPlaying) {
                        await videoRef.current.play();
                    }
                } catch (error) {
                    console.error("视频播放失败: ", error);
                } finally {
                    setIsAttemptingToPlay(false); // 重置尝试播放标志
                }
            }
        };

        const pauseVideo = () => {
            if (videoRef.current && !videoRef.current.paused && !isAttemptingToPlay) { // 检查是否正在尝试播放
                videoRef.current.pause();
            }
        };

        if (isVideoInViewport) {
            videoRef.current.playbackRate = 0.75;
            playVideo();
        } else {
            pauseVideo();
        }
    }, [isVideoInViewport]);


    return (
        <div className='flex flex-1 justify-center p-4'>
            <div className={`flex justify-center items-center rounded-3xl p-2 ${direction ? 'animate-slide-in-left' : 'animate-slide-in-right'}`}>
                 <video
                    ref={videoRef}
                    className="object-contain max-w-full max-h-128 md:max-h-full rounded-2xl shadow-2xl shadow-gray-200"
                    preload="auto"
                    muted
                    loop
                    playsInline
                >
                    <source src={src} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>


    );
};

export default VideoComponent;
