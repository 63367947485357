// ImageComponent.js
import React, { useEffect, useRef, useState } from 'react';
const ImageComponent = ({ src }) => {
    const imgRef = useRef(null); // 修改为imgRef
    const [isImgInViewport, setIsImgInViewport] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsImgInViewport(entry.isIntersecting),
            { threshold: 1 } // 完全进入视口才触发
        );

        if (imgRef.current) {
            observer.observe(imgRef.current); // 观察imgRef
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    // 无需播放或暂停操作，所以移除相关的useEffect

    return (
        <div className='flex flex-1 justify-center p-4'>
            <div className="flex justify-center items-center rounded-3xl p-2 animate-slide-in-right transition-transform duration-500">
                <img
                    ref={imgRef} // 设置ref为imgRef
                    src={src} // 使用src而不是videoSrc
                    alt="Content"
                    className="object-contain w-full max-h-128 md:max-h-full rounded-2xl shadow-2xl shadow-gray-200"
                />
            </div>
        </div>
    );
};


export default ImageComponent;
