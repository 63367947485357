// messages.js
export const messages = {
    en: {
        home: "Home",
        blog: "Blog",
        about: "About",
        aboutMe: "Hello,",
        aboutMeDescription: "I am a computer science student at the University of Melbourne. I am passionate about bringing every idea in my mind to life through hands-on practice. Even though I have encountered more and more obstacles in the process, I have also experienced the charm that arises when art design merges with hardware and software. Therefore, on the path of development, I do not want to confine myself to a small circle. Through this website, I hope to engage in broader communication and collaboration with everyone.",
        contactMe: "Contact Me",
        // Add translations for hobbies here
        photography: "Photography",
        hiking: "Hiking",
        cooking: "Cooking",
        cycling: "Cycling",
        movie: "Movie",
        painting: "Painting",
        gaming: "Gaming",
        traveling: "Traveling",
        eating: "Eating",
        hiphop: "HipHop",
        music: "Music",
        software: "Software",
        hardware: "Hardware",
        footerTitle: "Never miss a beat.",
        footerDescription: "Sign up now to stay in the loop and get access to exclusive offers. You can unsubscribe at any time.",
        footerCopyright: "© 2024 Dongju. All rights reserved.",
        // Success messages
        emailUploadSuccess: "Email uploaded successfully!",
        // Error messages
        emailUploadError: "An error occurred while uploading.",
        // Validation messages
        invalidEmailError: "Please enter a valid email address.",
        // Other messages
        subscriptionConfirmation: "You've successfully subscribed!",
        unsubscribeSuccess: "You've successfully unsubscribed.",
        "$ grep . . . ": "$ grep . . . ",
        Download: "Download",
        comment: "improves because of you : )",
        commit: "commit",
        submit: "Content uploaded successfully!"
    },
    cn: {
        home: "首页",
        blog: "博客",
        about: "关于",
        aboutMe: "你好啊，",
        aboutMeDescription: "我是墨尔本大学计算机专业的学生。我非常喜欢将脑海中的每一个点子都动手实践出来。虽然在实践中我遇到了越来越多的阻碍，但我也体验到了艺术设计与硬件设备和软件融合时所产生的魅力。因此，在开发的道路上，我不想将自己限制在一个小圈子内，我希望通过这个网站能够与大家进行更广泛的交流与合作。",
        contactMe: "联系我",
        // 为爱好添加中文翻译
        photography: "摄影",
        hiking: "徒步",
        cooking: "烹饪",
        cycling: "骑行",
        movie: "电影",
        painting: "绘画",
        gaming: "游戏",
        traveling: "旅行",
        eating: "美食",
        hiphop: "hiphop",
        music: "音乐",
        software: "软件",
        hardware: "硬件",
        footerTitle: "不错过任何消息",
        footerDescription: "立即登入，随时获取最新信息。您可以随时取消订阅。",
        footerCopyright: "© 2024 Dongju. 版权所有。",
         // 成功消息
         emailUploadSuccess: "Email上传成功！",
         // 错误消息
         emailUploadError: "上传时发生错误。",
         // 验证消息
         invalidEmailError: "请输入有效的邮箱地址。",
         // 其他消息
         subscriptionConfirmation: "您已成功订阅！",
         unsubscribeSuccess: "您已成功取消订阅。",
         "$ grep . . . ": "$ grep . . . ",
         Download: "下载",
         comment:" 因你而完善 : )",
         commit: "提交",
         submit: "内容上传成功!"
    },
    // 其他语言的翻译...
};
