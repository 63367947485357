// src/contexts/BlogsContext.js
import React, { createContext, useContext, useState,useRef } from 'react';

const BlogsContext = createContext();

export const useBlogs = () => useContext(BlogsContext);

export const BlogsProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [skip, setSkip] = useState(0);
    const [selectedTag, setSelectedTag] = useState(null); // 新增状态：选中的标签
    const hasMoreRef = useRef(true);
    const scrollPosition = useRef(0); // 添加用于保存滚动位置的ref
    const preserveScrollPosition = useRef(false); // 新增标志位

     // 新增方法：设置选中的标签
     const handleTagSelection = (tagName) => {
        setSelectedTag(tagName);
    };

    return (
        <BlogsContext.Provider value={{ blogs, setBlogs, skip, setSkip,hasMoreRef,scrollPosition,preserveScrollPosition, selectedTag, handleTagSelection   }}>
            {children}
        </BlogsContext.Provider>
    );
};