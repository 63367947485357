import React from 'react';
import SectionAboutMe from './SectionAboutMe';
import SectionContactMe from './SectionContactMe';

const About = () => {

    return (
        <>
            <SectionAboutMe  />
            <SectionContactMe />
        </>
    );
};

export default About;
