import React, { useEffect, useState } from 'react';
import { useTypewriter } from '../modifier/useTypewriter';
import { useIntl } from 'react-intl';
import ContentSection from './ContentSection';

const ProductDetailPage = () => {
    const productInfo = window.location.pathname.split('/').pop(); // 或者根据你的路由结构调整获取方式


    const intl = useIntl();
    const languageCode = window.location.pathname.split('/')[1] || 'en';
    const [isLoading, setIsLoading] = useState(true); // 新增isLoading状态
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > window.innerHeight);

    const [sectionsData, setSectionsData] = useState({ en: {}, cn: {} });
    const [appName, setAppName] = useState('');
    const [downloadLink, setDownloadLink] = useState('');
    const [introText, setIntroText] = useState(''); // Manage introText as part of state
    const typewriterConfig = languageCode === 'cn' ? { speed: 150, deleteSpeed: 50, pause: 4000 } : { speed: 80, deleteSpeed: 40, pause: 6000 };

    const bucketName = process.env.REACT_APP_BUCKET_NAME;
    const region = process.env.REACT_APP_REGION_CODE;
    const productPath = process.env.REACT_APP_PRODUCT_PATH
    const productJsonUrl = `https://${bucketName}.obs.${region}.myhuaweicloud.com${productPath}/${productInfo}/${productInfo}.json`;

    useEffect(() => {
        setIsLoading(true);
        fetch(productJsonUrl)
            .then(response => response.json())
            .then(data => {
                // Assume introText is part of your fetched data
                const { appName, introText, downloadLink, sections } = data[languageCode];
                setSectionsData({ appName, downloadLink, sections });
                setAppName(appName)
                setDownloadLink(downloadLink)
                // setIntroText("introText asd  asd as d d we wef wf d{{242342342342}}sd fdgfsd gd fg dfg dfg df gdf gdf g "); // Set introText here
                setIntroText(introText);
                setIsLoading(false)
            })
            .catch(error => {
                console.error("Failed to load sections data:", error);
            })
    }, []);


    // 使用introText作为useTypewriter的输入，直接在组件函数中调用
    const [typewriterText, isCursorVisible] = useTypewriter(
        introText,
        typewriterConfig.speed,
        typewriterConfig.deleteSpeed,
        typewriterConfig.pause
    );

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            style={{
                width: isWideScreen ? `${window.innerHeight * 1.1}px` : '100%',
                margin: 'auto',
            }}
            className={`flex flex-col  min-h-screen p-8 `}
        >{isLoading ?
            <></> :
            (
                <>
                    <div className='flex flex-col p-4'>
                        <div className='flex flex-col h-128 md:h-96 justify-start items-start animate-slide-up-slow relative'>
                            <div>
                                <h2 className="text-2xl md:text-4xl lg:text-6xl font-bold animate-slideIn items-center justify-center">{appName}</h2>
                            </div>
                            <div className=" text-base md:text-lg lg:text-xl font-semibold tracking-wider mt-10 md-14">{typewriterText}
                                {isCursorVisible && <span className="animate-pulse transition-opacity duration-500 ease-out">|</span>}
                            </div>

                            <a href={downloadLink} target="_blank" rel="noopener noreferrer" className='absolute flex flex-row items-center bottom-2 right-2 p-2 bg-gray-200 rounded-md hover:bg-gray-100 animate-expand-and-rise'>
                                <p>{intl.formatMessage({ id: 'Download' })}</p>
                                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-1">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </a>
                        </div>
                    </div>


                    {
                        sectionsData.sections && sectionsData.sections.map((section, index) => (
                            <ContentSection key={index} section={section} />
                        ))
                    }

                </>
            )}
        </div>
    );
};

export default ProductDetailPage;
