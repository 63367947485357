import { useEffect, useState } from 'react';

export function useTypewriter(text, speed = 100, deleteSpeed = 50, pause = 2000) {
  const [output, setOutput] = useState('');
  const [isCursorVisible, setIsCursorVisible] = useState(true);

  useEffect(() => {
    // 如果文本为空，则直接返回并隐藏光标
    if (!text) {
      setOutput(''); // 确保输出也是空的
      return; // 提前退出effect
    }

    const sections = text.split(/{{|}}/);
    let currentSection = 0;
    let index = 0; // 当前节打字位置
    let isDeleting = false; // 是否删除模式
    let permanentText = ''; // 永久显示的文本

    function type() {
      if (currentSection >= sections.length) {
        setTimeout(() => {
          setIsCursorVisible(false); // 在指定的延时后隐藏光标
        }, 4000);

        return;
      }

      let section = sections[currentSection];
      let updatedText = permanentText;

      if (isDeleting) {
        updatedText += section.substr(0, --index);
      } else {
        updatedText += section.substr(0, ++index);
      }

      setOutput(updatedText);

      if (!isDeleting && index === section.length) {
        if ((currentSection % 2) === 1) {
          setTimeout(() => {
            isDeleting = true;
            type();
          }, pause);
        } else {
          permanentText += section; // 更新永久文本
          currentSection++;
          index = 0;
          type();
        }
      } else if (isDeleting && index === 0) {
        isDeleting = false;
        currentSection++;
        type();
      } else {
        setTimeout(type, isDeleting ? deleteSpeed : speed);
      }
    }

    type();
  }, [text]);

  return [output, isCursorVisible];
}
