import React, { useEffect, useRef, useState } from 'react';
import VideoComponent from './VideoComponent';
import ImageComponent from './ImageComponent';

const ContentSection = ({ section  }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [hasBeenVisible, setHasBeenVisible] = useState(false); // 新增状态
    const sectionRef = useRef(null);
    const contentRef = useRef(null);

    const { title, items, mediaSrc, isReverse } = section;

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                // 当 entry.isIntersecting 为 true 时，设置 isVisible 和 hasBeenVisible
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    setHasBeenVisible(true); // 标记组件已变为可见
                }
            });
        }, { threshold: 0.7 }); // 可以根据需要调整触发条件

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    setHasBeenVisible(true);
                }
            });
        }, { threshold: 0.8 });
    
        if (contentRef.current) {
            observer.observe(contentRef.current);
        }
    
        return () => {
            if (contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);
    

    // 使用 hasBeenVisible 确保内容一旦出现后不再隐藏
    if (!isVisible && !hasBeenVisible) {
        return <div ref={sectionRef} className="h-176"></div>;
    }

    const Content = (
        <div ref={contentRef} className="flex flex-col w-full md:w-1/2 p-4 text-lg md:text-xl lg:text-2xl text- font-bold sm:items-center justify-center">
            <div className="flex flex-col items-start md:w-1/2"> {/* 新增的div，用于包裹h2和ul */}
                <h2 className="text-black mb-4 animate-reveal-up">{title}</h2>
                <ul className={`list-disc pl-5 space-y-2 text-gray-700  animate-slide-up-slow`}>
                    {items.map((item, index) => (
                        <li key={index} className="text-sm md:text-base tracking-widest">{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );

    const isVideo = mediaSrc && mediaSrc.endsWith('.mp4');

    const MediaComponent = isVideo ? (
        <VideoComponent src={mediaSrc} direction={isReverse}/>
    ) : (
        <ImageComponent src={mediaSrc} />
    );

    return (
        <div ref={sectionRef} className={`flex flex-col md:flex-row w-full h-full  md:h-176 p-4 gap-4 md:gap-10 ${isReverse ? 'md:flex-row-reverse' : ''} `}>
            {Content}
            {MediaComponent}
        </div>
    );
};

export default ContentSection;
