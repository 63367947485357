import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import Carousel from './Carousel';
import ipad from '../assets/iapd.jpeg'
import asd from '../assets/Frame 34.png'
import ProductCard from './ProductCard';




const MainContent = () => {
  const intl = useIntl();

  const [softwareItems, setSoftwareItems] = useState([]);
  const [hardwareItems, setHardwareItems] = useState([]);
  const softwareRef = useRef(null);
  const hardwareRef = useRef(null);

  const languageCode = window.location.pathname.split('/')[1] || 'en';

  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const region = process.env.REACT_APP_REGION_CODE;
  const hardwarePath = process.env.REACT_APP_HARDWARE_PATH;
  const hardwareJsonUrl = `https://${bucketName}.obs.${region}.myhuaweicloud.com${hardwarePath}`;

  const softwarePath = process.env.REACT_APP_SOFTWARE_PATH;
  const softwareJsonUrl = `https://${bucketName}.obs.${region}.myhuaweicloud.com${softwarePath}`;

  // 动态加载software部分
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some(entry => entry.isIntersecting)) {
          fetch(softwareJsonUrl)
            .then(response => response.json())
            .then(data => setSoftwareItems(data));
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // 10%的元素可见时触发
      }
    );

    if (softwareRef.current) {
      observer.observe(softwareRef.current);
    }
    return () => observer.disconnect();
  }, []);

  // 动态加载hardware部分
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some(entry => entry.isIntersecting)) {
          fetch(hardwareJsonUrl)
            .then(response => response.json())
            .then(data => setHardwareItems(data));
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (hardwareRef.current) {
      observer.observe(hardwareRef.current);
    }
    return () => observer.disconnect();
  }, []);

  return (
    <div className="flex-grow p-6 overflow-auto bg-gray-100 shadow">
      <Carousel />
      <section ref={softwareRef} className="mt-4 sm:mt-10 md:mt-13 lg:mt-216 md:h-144">
        <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-5xl font-bold mb-4 sm:mb-6 md:mb-8 lg:mb-9 text-gray-700">
          {intl.formatMessage({ id: 'software' })}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 animate-slideIn">

          {softwareItems.map((item) => (
              <ProductCard
              key={item.enName} // Assuming enName is unique
              name={languageCode === 'cn' ? item.cnName : item.enName}
              slogan={languageCode === 'cn' ? item.cnSlogan : item.enSlogan}
              imageUrl={item.url}
              date={item.date}
              productInfo={item.productInfo}
              // You can also dynamically display date and productInfo based on languageCode if needed
            />
             ))}
        </div>
      </section>
      {hardwareItems.length > 0 && ( // 只有当hardwareItems不为空时，才渲染硬件部分
      <section ref={hardwareRef} className="mt-4 sm:mt-10 mb-4 sm:mb-6 md:mb-8 lg:mb-9 min-h-screen">
        <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-5xl font-bold mb-4 text-gray-700">
          {intl.formatMessage({ id: 'hardware' })}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 animate-slideIn">
          {hardwareItems.map((item) => (
            <ProductCard
              key={item.enName} // Assuming enName is unique
              name={languageCode === 'cn' ? item.cnName : item.enName}
              slogan={languageCode === 'cn' ? item.cnSlogan : item.enSlogan}
              imageUrl={item.url}
              date={item.date}
              productInfo={item.productInfo}
              // You can also dynamically display date and productInfo based on languageCode if needed
            />
          ))}
        </div>
      </section>
    )}
    </div>
  );
};

export default MainContent;
